// src/layout/Style.scss

$primary-color: #4a90e2;
$secondary-color: #ffffff;
$background-color: #f4f6f8;
$sidebar-bg-color: #333a45;
$sidebar-text-color: #ffffff;
$sidebar-hover-color: lighten($primary-color, 15%);
$divider-color: rgba(255, 255, 255, 0.2);

.layout {
  display: flex;
  transition: all 0.3s ease;

  // Sidebar styling
  &__drawer {
    .MuiDrawer-paper {
      background-color: $sidebar-bg-color;
      color: $sidebar-text-color;
      box-shadow: 2px 0 5px rgba(0, 0, 0, 0.15);
      transition: width 0.3s ease;
    }
  }

  // Admin Info Section
  &__admin-info {
    display: flex;
    align-items: center;
    padding: 1rem;
    justify-content: space-between;
    background: linear-gradient(135deg, #4a90e2 0%, #5c5f9e 100%);
    color: $secondary-color;

    .layout__admin-details {
      display: flex;
      align-items: center;
    }

    .layout__avatar {
      width: 50px;
      height: 50px;
      margin-right: 1rem;
      border: 2px solid $secondary-color;
    }

    .layout__admin-name {
      font-weight: 500;
    }
  }

  // Sidebar Divider
  &__divider {
    background-color: $divider-color;
    margin: 0.5rem 1rem;
  }

  // Sidebar Navigation
  &__list-item {
    display: flex;
    align-items: center;
    padding: 0.8rem 1.5rem;
    color: $sidebar-text-color;
    transition: background-color 0.3s ease, padding-left 0.3s ease;
    
    &:hover {
      background-color: $sidebar-hover-color;
      padding-left: 1.8rem;
      transition: all .2s;
    }

    .layout__icon {
      margin-right: 1rem;
      font-size: 1.5rem;
      transition: transform 0.3s ease;
    }

    &.active {
      background-color: $primary-color;
    }
  }

  &__main {
    flex: 1;
    background-color: $background-color;
  }

  &__appbar {
    background-color: $primary-color;
    color: $secondary-color;
  }

  &__title {
    font-size: 1.25rem;
    font-weight: 600;
  }

  &__content {
    padding: 2rem;
    background-color: $background-color;
    min-height: 100vh;
  }

  // Sidebar expanded and collapsed states
  &--expanded .layout__list-item .MuiListItemText-root {
    display: inline;
  }

  &--collapsed .layout__list-item .MuiListItemText-root {
    display: none;
  }
  
  &--collapsed .layout__icon {
    margin-right: 0;
  }
}

.layout {
  .mobile-layout {
    display: none;
    visibility: hidden;
  }
}

@media (max-width: 480px) {

  .mobile-drawer{
    display: none;
    visibility: hidden;
  }

  .layout {
    .mobile-layout {
      display: block !important;
      visibility: visible !important;
      width: 100%;
      position: fixed;
      bottom: 0;
      right: 0;
      background-color: #fff;
      // border-top: 1px solid #ddd;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      padding: 0.5rem 0;
      // z-index: 1000;
      // left: 0;
      margin-top: 20px !important;

      .footer-icon{
        color: #333a45;
        font-size: 20px;
        display: flex;

        &:active {
          color: #4a90e2;
        }
      }

      .home-icon {
        // margin-top: -20px;
        // margin-bottom: 20px;
        padding: 20px;
        background: #359502;
        animation: bounceUp 1s ease-out;
        color: #ffffff;
      }
      
      .footer-icons {
        // margin-left: 15px !important;
        display: flex !important;
        justify-content: space-evenly !important;
      }
      
      @keyframes bounceUp {
        0% {
          margin-top: 0;
        }
        100% {
          margin-bottom: 20px;
          margin-top: -20px;
        }
      }
    }
  }
}