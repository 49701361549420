.add-order {
  padding: 2rem;
  background-color: #f4f6f8;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);

  .MuiTextField-root {
    margin-bottom: 1rem;
  }

  .MuiButton-contained {
    margin-top: 1rem;
    background-color: #4a90e2;
    color: white;

    &:hover {
      background-color: #3b7fb0;
    }
  }
}

// View Orders
.orders-view {
  padding: 20px;

  .table-title {
    margin-bottom: 20px;
    color: #333;
    font-weight: bold;
  }

  .MuiTableContainer-root {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }

  table {
    width: 100%;
    border-collapse: collapse;

    th {
      background-color: #1976d2;
      color: white;
      padding: 12px;
      font-weight: bold;
      text-align: left;
    }

    td {
      padding: 12px;
      border-bottom: 1px solid #e0e0e0;
      color: #555;
      vertical-align: middle;

      &:last-child {
        text-align: center;
      }
    }

    tr:hover {
      background-color: #f1f1f1;
    }

    .MuiIconButton-root {
      padding: 8px;

      &:hover {
        background-color: #f5f5f5;
      }

      &.MuiIconButton-colorPrimary:hover {
        color: #1565c0;
      }

      &.MuiIconButton-colorSecondary:hover {
        color: #d32f2f;
      }

      &.MuiIconButton-colorDefault:hover {
        color: #555;
      }
    }
  }
}


// All Products
// Shopping Cart
.floating-cart__container {
  position: fixed;
  bottom: 80px;
  right: 50px;
  z-index: 1000;

  .floating-cart {
    background: linear-gradient(135deg, #ff6f61, #ff9057); // Attractive gradient
    color: #fff; // Icon color
    border-radius: 50%; // Fully rounded button
    box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.3); // Add a shadow for depth
    width: 70px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;

    &:hover {
      transform: scale(1.15); // Slightly enlarge on hover
      box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.4);
    }

    &:active {
      transform: scale(0.95); // Slightly shrink on click
    }

    // Floating Animation
    animation: float 3s ease-in-out infinite;
  }

  .live-cart {
    .MuiBadge-badge {
      background-color: #ffc107; // Bright badge color
      color: #000; // Text color inside the badge
      font-size: 0.9rem; // Slightly larger badge text
      transform: translate(10px, -10px); // Position adjustment
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); // Shadow on badge
    }
  }
}

// Keyframes for Floating Animation
@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px); // Smooth upward float
  }
  100% {
    transform: translateY(0);
  }
}





// style.scss
.product-card {
  border: 1px solid #ddd;
  padding: 16px;
  text-align: center;
  border-radius: 8px;
}

.product-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.cart-drawer {
  width: 300px;
  padding: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #3f51b5;
  color: white;
  padding: 16px;
}



/* CheckoutPage.scss */

/* Checkout Container */
.checkout-container {
  min-height: 150vh;
  overflow: scroll;
  max-width: 100%;
}

/* Grid Layout */
.checkout-grid {
  display: grid;
  gap: 16px;
}

/* Product Summary */
.product-summary {
  padding: 16px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Checkout Form */
.checkout-form {
  padding: 16px;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Buttons */
.checkout-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
}

.checkout-buttons .MuiButton-root {
  width: 45%;
}

/* Make the layout responsive */
@media (max-width: 768px) {
  .checkout-container {
    max-width: 100%;
  }
}


@media (max-width: 480px) {
  .product-card {
    max-height: '100px !important'

  }

  .product-image {
    max-height: '100px !important'

  }

  @media (max-width: 480px) {
    .text-center {
      font-size: 18px !important;
    }

    .product-card-container {
      .product-image {
        height: 100px !important;
      }
      .product-title {
        font-size: 16px !important;
      }
      .product-price {
        font-size: 14px;
      }
      .product-weight {
        font-size: 12px;
      }
      .product-button {
        font-size: 10px;
      }
    }
  
  
    .category-card {
      .card-title {
        font-size: 0.875rem; // Adjust title for mobile
      }
      .card-description {
        font-size: 0.75rem; // Adjust description for mobile
      }
    }
  }
  
}



// Category Cards Container
.cards-container {
  text-align: center;

  .text-center {
    padding: 15px 0;
  }
}

.text-center {
  text-align: center;
}



// Notifications
.notification-page {
  margin-top: 40px;
  padding: 20px;
  min-height: 110vh;

  .loading-spinner {
    text-align: center;
    margin-top: 50px;
  }

  .notification-grid {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }

  .notification-card {
    overflow: visible;
    transition: transform 0.2s, box-shadow 0.2s;

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
    }

    .notification-badge {
      font-family: Arial, sans-serif;
    }

    .notification-image {
      border-radius: 5px 5px 0 0;
      object-fit: cover;
    }

    .notification-content {
      padding: 16px;

      .notification-title {
        font-size: 1.25rem;
        font-weight: 600;
        margin-bottom: 8px;
      }

      .notification-sale-type {
        font-size: 0.85rem;
        font-weight: 700;
        text-transform: uppercase;
      }

      .notification-desc {
        font-size: 0.9rem;
        color: #555;
      }
    }
  }
}

