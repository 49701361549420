.orders-view {
  min-height: 140vh !important;
  margin-bottom: 60px;

  .orders-table {
    width: 100%;
    min-height: 140vh !important;

    // Ensure the table takes full width on smaller screens
    .table-container {
      overflow-x: auto;
    }

    .table-heads {
      table-layout: fixed; // Ensures the table columns stay within the viewport

    }

    table {
      width: 100%;

      th, td {
        word-wrap: break-word;
        padding: 10px 8px;
      }

      // Reduce font size on mobile
      @media (max-width: 600px) {
        th, td {
          font-size: 12px;
        }

        .table-cell {
          font-size: 12px;
          padding: 8px 6px;
        }

        .icon-buttons {
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .orders-view {
    .orders-table {
      padding: 0 10px;
    }

    // Adjust header typography
    h4 {
      font-size: 16px;
    }

    // .MuiTableHead-root {
    //   display: none; // Hide the headers on small screens for better space usage
    // }

    // Show a simple list view instead of the table on mobile
    .table-container {
      .table-body {
        display: block;
        width: 100%;
      }

      .table-row {
        display: block;
        width: 100%;
        margin-bottom: 10px;
        border-bottom: 1px solid #ddd;
      }

      .table-row .table-cell {
        display: flex;
        justify-content: space-between;
        padding: 10px;
        font-size: 12px;
      }
    }
  }
}
