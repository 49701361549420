/* SupportPage.module.scss */
.support-container {
  padding: 2rem;
  width: 100%;
  max-width: 600px;
  margin: auto;
  margin-top: 10rem;

  @media (max-width: 768px) {
    padding: 1.5rem;
  }
}

.paper {
  padding: 2rem;
}

.title {
  text-align: center;
  margin-bottom: 1.5rem;
}

.description {
  text-align: center;
  margin-bottom: 2rem;
}

.form-field {
  margin-bottom: 1.5rem;
}

.submit-button {
  width: 100%;
}

@media (max-width: 600px) {
  .submit-button {
    width: 100%;
  }
}